import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";
import Header from "../shared/Header/Header";
import SettingsLeftMenu from "../shared/SettingsLeftMenu/SettingsLeftMenu";
import "./ActivityLog.scss";
import { UikDivider, UikButton } from "@uik";
import DatePicker from "react-datepicker";
import download from "downloadjs";
import httpClient from "../../lib/HttpClient";
import security from "../../services/Security";
import ActionCable from "actioncable";
import axios from "../../lib/axios-client";
import Report from "./Report";
import Button from "../Common/Buttons/ButtonTwoState";

export const ActivityLog = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [reports, setReports] = useState([]);
  const [generateButtonDisabled, setGenerateButtonDisabled] = useState(false);
  const [fromDateError, setFromDateError] = useState("");
  const [toDateError, setToDateError] = useState("");

  useEffect(() => {
    const token = security.getToken();
    const cable = ActionCable.createConsumer(
      `wss://${
        process.env.NODE_ENV === "production"
          ? `${process.env.REACT_APP_API_URL.replace(/(^\w+:|^)\/\//, "")}:3000`
          : "localhost:3000"
      }/cable`
    );
    cable.subscriptions.create("ReportsChannel", {
      received: handleReceiveReport,
    });

    httpClient.get(`/reports.json`).then(({ data }) => {
      let reports = [];
      data.forEach((report) => {
        reports.push({
          id: report.id,
          filename: report.filename,
        });
      });
      setReports(reports);
    });
  }, []);

  const handleReceiveReport = (data) => {
    setGenerateButtonDisabled(false);
    setReports((reports) => {
      reports.unshift(data);
      let newReports = reports.slice(0, 5);
      return newReports;
    });
  };

  const generateReport = () => {
    let passedValidation = true;
    if (!fromDate) {
      setFromDateError("Date field cannot be blank");
      passedValidation = false;
    }
    if (!toDate) {
      setToDateError("Date field cannot be blank");
      passedValidation = false;
    }
    if (fromDate > toDate) {
      setFromDateError("From date cannot be greater than to date");
      setToDateError("From date cannot be greater than to date");
      passedValidation = false;
    }
    if (passedValidation) {
      setGenerateButtonDisabled(true);
      httpClient
        .post(`/reports`, {
          dates: {
            from_date: fromDate,
            to_date: toDate,
          },
        })
        .then(() => {
          console.log("success!");
        });
    }
  };

  const downloadReport = (id, reportName) => {
    axios({
      method: "get",
      url: `/reports/download/${id}`,
      responseType: "arraybuffer",
      headers: { Authorization: `Bearer ${security.getToken()}` },
    }).then((response) => {
      download(response.data, `${reportName}.csv`, "text/csv");
    });
  };

  const clearReports = () => {
    httpClient.delete("/reports/destroy/all").then(() => {
      setReports([]);
    });
  };

  const renderReports = () =>
    reports.map((report, index) => {
      //filename conversions
      const filename = report.filename;
      let startDateArr = filename.substring(0, 10).split("-");
      let endDateArr = filename.substring(11).replace(".csv", "").split("-");
      const startDate = new Date(
        startDateArr[0],
        startDateArr[1] - 1,
        startDateArr[2]
      );
      const endDate = new Date(endDateArr[0], endDateArr[1] - 1, endDateArr[2]);

      const reportName = `${startDate.toLocaleString("default", {
        month: "long",
      })} ${startDate.getDate()}, ${startDate.getFullYear()} - ${endDate.toLocaleString(
        "default",
        { month: "long" }
      )} ${endDate.getDate()}, ${endDate.getFullYear()}`;

      if (index === reports.length - 1) {
        return (
          <React.Fragment key={index}>
            <Report
              reportName={reportName}
              id={report.id}
              downloadReport={downloadReport}
            />
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment key={index}>
            <Report
              reportName={reportName}
              id={report.id}
              downloadReport={downloadReport}
            />
            <UikDivider />
          </React.Fragment>
        );
      }
    });

  const renderDownloadReports = () => {
    return (
      <div className="block">
        <div className="content">
          <div className="heading">
            <div>Download Activity Log</div>
            <div className="sub-heading">
              Download a CSV file of all user activity within a date range
            </div>
          </div>
        </div>
        <UikDivider />
        <div className="reports">{renderReports()}</div>
        <UikDivider />
        <div className="content">
          <button onClick={clearReports} className="clear">
            Clear
          </button>
        </div>
      </div>
    );
  };

  return (
    <Container fluid className="container">
      <Header />
      <Row className="row">
        <SettingsLeftMenu />
        <Col sm={12} md={5}>
          <div className="block">
            <div className="content">
              <div className="heading">
                <div>Activity Log</div>
                <div className="sub-heading">
                  Create a new activity log based on a date range.
                </div>
              </div>
            </div>
            <UikDivider />
            <div className="content">
              <div className="body">
                <div className="date">
                  <div>
                    <span className="uik-content-title__wrapper">
                      FROM DATE
                    </span>
                  </div>
                  <DatePicker
                    className={`uik-input__input${
                      fromDateError.length ? ` error` : ``
                    }`}
                    selected={fromDate}
                    onChange={(date) => {
                      setFromDate(date);
                      setFromDateError("");
                    }}
                    disabled={generateButtonDisabled}
                  />
                  <div className="error">{fromDateError}</div>
                </div>
                <div className="date">
                  <div>
                    <span className="uik-content-title__wrapper">TO DATE</span>
                  </div>
                  <DatePicker
                    className={`uik-input__input${
                      toDateError.length ? ` error` : ``
                    }`}
                    selected={toDate}
                    onChange={(date) => {
                      setToDate(date);
                      setToDateError("");
                    }}
                    disabled={generateButtonDisabled}
                  />
                  <div className="error">{toDateError}</div>
                </div>
              </div>
            </div>
            <UikDivider />
            <div className="content">
              <Button
                activeText="Generate Log"
                disabledText="Processing"
                disabled={generateButtonDisabled}
                onClick={generateReport}
              />
            </div>
          </div>
          {reports.length ? renderDownloadReports() : null}
        </Col>
      </Row>
    </Container>
  );
};
